import styled from 'styled-components';
import { getSpacing, typography } from 'stylesheet';

export const Wrapper = styled.div`
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  padding: 40px 50px;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin-top: ${getSpacing(4)};
  width: 420px;
  height: 560px;
`;

export const Title = styled.h1`
  ${typography.h1}
  font-weight: 500;
  margin-bottom: ${getSpacing(4)};
  font-size: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.deepBlue};
`;
export const Description = styled.p`
  margin-bottom: ${getSpacing(8)};
  max-width: ${getSpacing(80)};
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${getSpacing(2)};
  width: 100%;
`;

export const ForgotPassword = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  text-decoration: none;
  align-self: center;
  margin-top: ${getSpacing(4)};
  &:hover {
    cursor: pointer;
  }
`;

export const ResendCode = styled(ForgotPassword)``;
