import React, { useState } from 'react';
import { CognitoUser } from '@aws-amplify/auth';
import LoginForm from './steps/LoginForm';
import ForgottenPassword from './steps/ForgottenPassword';
import ResetPassword from './steps/ResetPassword';
import { CreateNewPassword } from './steps/CreateNewPassword';
import { Wrapper } from './styles';

export enum LoginFlowSteps {
  LOGIN = 'LOGIN',
  RESET_PASSWORD = 'RESET_PASSWORD',
  FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',
  CREATE_NEW_PASSWORD = 'CREATE_NEW_PASSWORD',
}

export const LoginFlow: React.FC = () => {
  const [step, setStep] = useState<LoginFlowSteps>(LoginFlowSteps.LOGIN);
  const [user, setUser] = useState<CognitoUser | undefined>();
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('');

  return (
    <Wrapper>
      {step === LoginFlowSteps.LOGIN && <LoginForm setStep={setStep} setUser={setUser} />}
      {step === LoginFlowSteps.FORGOTTEN_PASSWORD && (
        <ForgottenPassword setStep={setStep} setForgotPasswordEmail={setForgotPasswordEmail} />
      )}
      {step === LoginFlowSteps.RESET_PASSWORD && <ResetPassword user={user} />}
      {step === LoginFlowSteps.CREATE_NEW_PASSWORD && (
        <CreateNewPassword setStep={setStep} forgotPasswordEmail={forgotPasswordEmail} />
      )}
    </Wrapper>
  );
};

export default LoginFlow;
