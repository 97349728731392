import React, { Dispatch, SetStateAction, useState } from 'react';
import { Input } from 'atoms/Input/Input';
import { Button } from 'atoms/Button/Button';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { getAuthenticatedUser, getUserGroup, signIn, signOut, USER_GROUPS } from 'services/auth';
import { LoginFormSchema } from './LoginForm.schema';
import { LoginFlowSteps } from '../../LoginFlow';
import { CognitoUser } from '@aws-amplify/auth';
import { Description, StyledForm, Title, ForgotPassword } from '../../styles';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import { Error } from 'atoms/Error';
import { Field } from 'atoms/Field/Field';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { getSpacing } from 'stylesheet';
import { TEST_IDS } from 'constants/testIds';

type LoginFormProps = {
  setStep: Dispatch<SetStateAction<LoginFlowSteps>>;
  setUser: Dispatch<SetStateAction<CognitoUser | undefined>>;
};

export const LoginForm: React.FC<LoginFormProps> = ({ setStep, setUser }) => {
  const intl = useIntl();
  const router = useRouter();
  const { setUser: setAuthUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setErrors,
    dirty,
    isValid,
    resetForm,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    initialErrors: {
      email: '',
      password: '',
    },
    onSubmit: async values => {
      setIsLoading(true);
      const response = await signIn(values.email, values.password);
      const group = await getUserGroup(response);

      if (response.code === 'NotAuthorizedException' || response.code === 'UserNotFoundException') {
        resetForm({ values });
        setErrors({
          password: intl.formatMessage({ id: 'login.errors.emailPasswordIncorrect' }),
        });
        setIsLoading(false);
        await signOut();
        return;
      }

      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(response);
        setStep(LoginFlowSteps.RESET_PASSWORD);
        setIsLoading(false);
        return;
      }

      if (
        response.message === 'Authentication Error' ||
        !group ||
        (group !== USER_GROUPS.BUSINESS_UNIT_ADMIN && group !== USER_GROUPS.VTAIL_ADMIN)
      ) {
        resetForm({ values });
        setErrors({
          password: intl.formatMessage({ id: 'login.errors.authError' }),
        });
        setIsLoading(false);
        await signOut();
        return;
      }

      setAuthUser(response.attributes);

      // Log in the user
      await getAuthenticatedUser();
      const redirectPath =
        group === USER_GROUPS.VTAIL_ADMIN ? PATHS.ADMIN_COMPANIES : PATHS.EMPLOYEES;
      router.push(redirectPath);
    },
    validationSchema: LoginFormSchema,
  });

  return (
    <>
      <Title data-testid="title">
        <FormattedMessage id="login.title" />
      </Title>
      <Description>
        <FormattedMessage id="login.description" />
      </Description>
      <StyledForm onSubmit={handleSubmit} autoComplete="off">
        <Field>
          <Input
            data-testid={TEST_IDS.signInForm.email}
            name="email"
            type="text"
            placeholder={intl.formatMessage({ id: 'login.email-placeholder' })}
            onChange={handleChange}
            value={values.email}
          />
          {errors.email && !dirty && <Error data-testid="email-errors">{errors.email}</Error>}
        </Field>
        <Field>
          <Input
            data-testid={TEST_IDS.signInForm.password}
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: 'login.password-placeholder' })}
            onChange={handleChange}
            value={values.password}
          />
          {errors.password && !dirty && (
            <Error data-testid="password-errors">{errors.password}</Error>
          )}
        </Field>
        <Button
          text={isSubmitting ? 'Loading...' : intl.formatMessage({ id: 'login.login-button' })}
          data-testid={TEST_IDS.signInForm.button}
          block
          margin={`${getSpacing(11)} 0 0 0`}
          type="submit"
          disabled={!isValid || isSubmitting || isLoading}
        />
        <ForgotPassword onClick={() => setStep(LoginFlowSteps.FORGOTTEN_PASSWORD)}>
          <FormattedMessage id="login.forgotPassword" />
        </ForgotPassword>
      </StyledForm>
    </>
  );
};

export default LoginForm;
