import * as Yup from 'yup';

export const CreateNewPasswordSchema = Yup.object({
  code: Yup.string()
    .required('Verification code is required')
    .max(6, 'Verification code is too long')
    .test('length check', 'Verification code is too short', function (value) {
      if (value) {
        if (/^[0-9]{0,6}$/.test(value)) {
          return value.length === 6;
        } else {
          return this.createError({
            message: 'you must specify a number',
            path: 'code',
          });
        }
      }
      return false;
    }),
  password: Yup.string()
    .required('Password is required')
    .test('requirement check', 'Password doesn’t meet requirements', value => {
      if (value) {
        // if special symbols should add (?=.*[!@#$%^&*])
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value);
      }
      return false;
    }),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password doesn’t match')
    .required('Password confirmation is required'),
});
