import React, { useEffect } from 'react';
import LoginFlow from 'components/LoginFlow';
import Logo from 'atoms/Logo';
import styled from 'styled-components';
import { autoLogin } from 'services/auth';
import { NextPage } from 'next';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.deepBlue};
`;

const Login: NextPage = () => {
  useEffect(() => {
    autoLogin();
  }, []);

  return (
    <Wrapper>
      <Logo maxWidth={60} />
      <LoginFlow />
    </Wrapper>
  );
};

export default Login;
