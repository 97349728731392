import * as Yup from 'yup';

export const ResetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Required')
    .matches(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, 'Ensure correct format'),
  password_confirm: Yup.string()
    .required('Required')
    .test('password-match', 'Ensure passwords match', function (value) {
      return this.parent.password === value;
    }),
});
