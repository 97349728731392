import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button } from 'atoms/Button/Button';
import { Input } from 'atoms/Input/Input';
import { Error } from 'atoms/Error';
import { Field } from 'atoms/Field/Field';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginFlowSteps } from '../../LoginFlow';
import { ForgottenPasswordSchema } from './Forgotten.schema';
import { Description, StyledForm, Title } from 'components/LoginFlow/styles';
import { getSpacing } from 'stylesheet';
import { Icon } from 'components/AddArticleModal/styles';
import { forgotPassword } from 'services/auth';
import { TEST_IDS } from 'constants/testIds';

type ForgottenPasswordProps = {
  setStep: Dispatch<SetStateAction<LoginFlowSteps>>;
  setForgotPasswordEmail: Dispatch<SetStateAction<string>>;
};

export const ForgottenPassword: React.FC<ForgottenPasswordProps> = ({
  setStep,
  setForgotPasswordEmail,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldError,
    touched,
    isValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: '',
    },
    initialErrors: {
      email: '',
    },
    onSubmit: async () => {
      try {
        setIsLoading(true);
        const response = await forgotPassword(values.email);
        if (response) {
          setStep(LoginFlowSteps.CREATE_NEW_PASSWORD);
          setForgotPasswordEmail(values.email);
        }
        setIsLoading(false);
      } catch {
        setFieldError('email', 'This email doesn’t exist');
        setIsLoading(false);
      }
    },
    validationSchema: ForgottenPasswordSchema,
  });

  return (
    <>
      <Icon
        onClick={() => setStep(LoginFlowSteps.LOGIN)}
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.742188 6.35938C0.742188 6.50781 0.804688 6.64062 0.921875 6.75L6.17969 12.0234C6.29688 12.1328 6.42188 12.1797 6.5625 12.1797C6.85938 12.1797 7.08594 11.9609 7.08594 11.6719C7.08594 11.5312 7.03125 11.3906 6.9375 11.2969L4.60156 8.9375L2.28906 6.79688L4.10938 6.88281H14.7344C15.0312 6.88281 15.25 6.66406 15.25 6.35938C15.25 6.05469 15.0312 5.83594 14.7344 5.83594H4.10938L2.29688 5.92188L4.60156 3.78125L6.9375 1.42188C7.03906 1.32031 7.08594 1.1875 7.08594 1.03906C7.08594 0.75 6.85938 0.53125 6.5625 0.53125C6.42188 0.53125 6.28906 0.585938 6.14062 0.734375L0.921875 5.96875C0.804688 6.07812 0.742188 6.21094 0.742188 6.35938Z"
          fill="#2F4058"
        />
      </Icon>
      <Title>
        <FormattedMessage id="forgotPassword.title" />
      </Title>
      <Description>
        <FormattedMessage id="forgotPassword.description" />
      </Description>
      <StyledForm onSubmit={handleSubmit} data-testid={TEST_IDS.forgottenPassword.form}>
        <Field>
          <Input
            name="email"
            type="email"
            placeholder={intl.formatMessage({ id: 'forgotPassword.email' })}
            onChange={handleChange}
            autoComplete="false"
            data-testid={TEST_IDS.forgottenPassword.emailInput}
            value={values.email}
          />
          {touched.email && (
            <Error data-testid={TEST_IDS.forgottenPassword.emailInputError}>{errors.email}</Error>
          )}
        </Field>
        <Button
          text={intl.formatMessage({ id: 'forgotPassword.submit-button' })}
          type="submit"
          block
          margin={`${getSpacing(11)} 0 0 0`}
          disabled={!isValid || isSubmitting || isLoading}
          data-testid={TEST_IDS.forgottenPassword.submitButton}
        />
      </StyledForm>
    </>
  );
};

export default ForgottenPassword;
