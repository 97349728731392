import { defaultTheme } from 'constants/theme/defaultTheme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${defaultTheme.spacing(240)};
  display: flex;
  height: 90vh;
  max-height: 678px;
  background-color: ${defaultTheme.colors.white};
  border-radius: 5px;
`;

export const FormWrapper = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  padding: ${defaultTheme.spacing(4)} ${defaultTheme.spacing(6)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.separator};
`;

export const Icon = styled.svg`
  margin-right: ${defaultTheme.spacing(4)};
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.deepBlue};
`;
export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Close = styled.div`
  font-size: 13px;
  cursor: pointer;
`;
export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PreviewPanel = styled.div`
  position: relative;
  flex: 2;
  background-color: ${({ theme }) => theme.colors.deepBlue};
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form<{ removePadding?: boolean }>`
  padding: ${defaultTheme.spacing(7)} ${defaultTheme.spacing(10)};
  flex: 1;
  overflow: auto;
  ${props => props.removePadding && `padding: 0;`}
`;

export const Footer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${defaultTheme.spacing(4)};
  border-top: 1px solid ${({ theme }) => theme.colors.separator};
`;

export const FooterError = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  bottom: ${defaultTheme.spacing(20)};
  left: 0;
  padding: ${defaultTheme.spacing(7)} ${defaultTheme.spacing(10)};
`;

export const BackButton = styled.p`
  margin-left: ${defaultTheme.spacing(4)};
  font-weight: 500;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.colors.deepBlue};
`;

export const FooterInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FooterRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Preview = styled.p`
  position: absolute;
  top: 24px;
  left: 24px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.alertRed};
`;

export const Small = styled.p`
  font-size: 13px;
  margin-left: 0;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
