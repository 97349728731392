import React, { Dispatch, SetStateAction, useState } from 'react';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoginFlowSteps } from 'components/LoginFlow/LoginFlow';
import { Button } from 'atoms/Button/Button';
import { Input } from 'atoms/Input/Input';
import { Error } from 'atoms/Error';
import { Field } from 'atoms/Field/Field';
import { CreateNewPasswordSchema } from './CreateNewPassword.schema';
import { Description, ResendCode, StyledForm, Title } from 'components/LoginFlow/styles';
import { getSpacing } from 'stylesheet';
import { Icon } from 'components/AddArticleModal/styles';
import { forgotPassword, forgotPasswordSubmit, forgotPasswordSubmitType } from 'services/auth';
import { TEST_IDS } from 'constants/testIds';

type CreateNewPasswordProps = {
  setStep: Dispatch<SetStateAction<LoginFlowSteps>>;
  forgotPasswordEmail: string;
};

export const CreateNewPassword: React.FC<CreateNewPasswordProps> = ({
  setStep,
  forgotPasswordEmail,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supportMessage, setSupportMessage] = useState<string>('');

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    isValid,
    setFieldError,
    isSubmitting,
    handleBlur,
  } = useFormik({
    initialValues: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    initialErrors: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    validateOnBlur: true,
    onSubmit: async () => {
      try {
        setIsLoading(true);
        const payload: forgotPasswordSubmitType = {
          password: values.password,
          code: values.code,
          username: forgotPasswordEmail,
        };

        await forgotPasswordSubmit(payload);

        setStep(LoginFlowSteps.LOGIN);
        setIsLoading(false);
      } catch (error) {
        setFieldError('code', (error as Error).message);
        setSupportMessage('An error occurred - contact vTail for support');
        setIsLoading(false);
      }
    },
    validationSchema: CreateNewPasswordSchema,
  });

  const resendCodeHandler = async (email: string): Promise<void> => {
    await forgotPassword(email);
  };

  return (
    <>
      <Icon
        onClick={() => setStep(LoginFlowSteps.LOGIN)}
        width="16"
        height="13"
        viewBox="0 0 16 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.742188 6.35938C0.742188 6.50781 0.804688 6.64062 0.921875 6.75L6.17969 12.0234C6.29688 12.1328 6.42188 12.1797 6.5625 12.1797C6.85938 12.1797 7.08594 11.9609 7.08594 11.6719C7.08594 11.5312 7.03125 11.3906 6.9375 11.2969L4.60156 8.9375L2.28906 6.79688L4.10938 6.88281H14.7344C15.0312 6.88281 15.25 6.66406 15.25 6.35938C15.25 6.05469 15.0312 5.83594 14.7344 5.83594H4.10938L2.29688 5.92188L4.60156 3.78125L6.9375 1.42188C7.03906 1.32031 7.08594 1.1875 7.08594 1.03906C7.08594 0.75 6.85938 0.53125 6.5625 0.53125C6.42188 0.53125 6.28906 0.585938 6.14062 0.734375L0.921875 5.96875C0.804688 6.07812 0.742188 6.21094 0.742188 6.35938Z"
          fill="#2F4058"
        />
      </Icon>
      <Title>
        <FormattedMessage id="createNewPassword.title" />
      </Title>
      <Description>
        <FormattedMessage id="createNewPassword.description" />
      </Description>
      <StyledForm onSubmit={handleSubmit} data-testid={TEST_IDS.createNewPassword.form}>
        <Field>
          <Input
            name="code"
            type="text"
            placeholder={intl.formatMessage({ id: 'createNewPassword.code' })}
            onChange={handleChange}
            autoComplete="one-time-code"
            onBlur={handleBlur}
            value={values.code}
            maxLength={6}
            data-testid={TEST_IDS.createNewPassword.codeInput}
          />
          {touched.code && (
            <Error data-testid={TEST_IDS.createNewPassword.codeInputError}>{errors.code}</Error>
          )}
        </Field>

        <Field>
          <Input
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: 'createNewPassword.password' })}
            onChange={handleChange}
            value={values.password}
            onBlur={handleBlur}
            autoComplete="new-password"
            data-testid={TEST_IDS.createNewPassword.passwordInput}
          />
          {touched.password && (
            <Error data-testid={TEST_IDS.createNewPassword.passwordInputError}>
              {errors.password}
            </Error>
          )}
        </Field>

        <Field>
          <Input
            name="passwordConfirmation"
            type="password"
            placeholder={intl.formatMessage({ id: 'createNewPassword.passwordConfirmation' })}
            onChange={handleChange}
            autoComplete="new-password"
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            data-testid={TEST_IDS.createNewPassword.passwordConfirmationInput}
          />
          {touched.passwordConfirmation && (
            <Error data-testid={TEST_IDS.createNewPassword.passwordConfirmationInputError}>
              {errors.passwordConfirmation}
            </Error>
          )}
        </Field>
        {errors.code && (
          <Field last>
            {supportMessage && (
              <Error center fullWidth>
                {supportMessage}
              </Error>
            )}
          </Field>
        )}
        <Button
          text={intl.formatMessage({ id: 'createNewPassword.submit-button' })}
          type="submit"
          block
          margin={`${getSpacing(11)} 0 0 0`}
          disabled={!isValid || isLoading || isSubmitting}
          data-testid={TEST_IDS.createNewPassword.submitButton}
        />
        <ResendCode
          onClick={() => resendCodeHandler(forgotPasswordEmail)}
          data-testid={TEST_IDS.createNewPassword.resendCodeButton}
        >
          <FormattedMessage id="createNewPassword.resendCode" />
        </ResendCode>
      </StyledForm>
    </>
  );
};

export default CreateNewPassword;
