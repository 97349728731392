import React from 'react';
import { Input } from 'atoms/Input/Input';
import { Button } from 'atoms/Button/Button';
import { Description, StyledForm, Title } from 'components/LoginFlow/styles';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResetPasswordSchema } from './ResetPasswordSchema';
import { CognitoUser } from '@aws-amplify/auth';
import { setNewPassword, getCurrentUserGroup, getCurrentUser, USER_GROUPS } from 'services/auth';
import { Error as ErrorComponent } from 'atoms/Error';
import { Field } from 'atoms/Field/Field';
import { useRouter } from 'next/router';
import { PATHS } from 'constants/paths';
import { useAuth } from 'contexts/AuthProvider/AuthProvider';
import { logError } from 'services/logging';

type ResetPasswordProps = {
  user: CognitoUser | undefined;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({ user }) => {
  const intl = useIntl();
  const router = useRouter();
  const { setUser: setAuthUser } = useAuth();
  const { handleSubmit, handleChange, values, errors, isValid, dirty, isSubmitting } = useFormik({
    initialValues: {
      password: '',
      password_confirm: '',
    },
    initialErrors: {
      password: '',
      password_confirm: '',
    },
    onSubmit: async values => {
      if (user) {
        await setNewPassword(user, values.password);
        const loggedInUser = await getCurrentUser();
        const userGroup = await getCurrentUserGroup();
        setAuthUser(loggedInUser);
        const redirectPath =
          userGroup && userGroup === USER_GROUPS.VTAIL_ADMIN
            ? PATHS.ADMIN_COMPANIES
            : PATHS.EMPLOYEES;
        router.push(redirectPath);
      } else {
        logError({ error: new Error('Cognito user missing'), errorName: 'ResetPasswordError' });
      }
    },
    validationSchema: ResetPasswordSchema,
  });
  return (
    <>
      <Title>
        <FormattedMessage id="resetPassword.title" />
      </Title>
      <Description>
        <FormattedMessage id="resetPassword.description" />
      </Description>
      <StyledForm onSubmit={handleSubmit}>
        <Field>
          <Input
            data-testid="password"
            name="password"
            type="password"
            placeholder={intl.formatMessage({ id: 'resetPassword.password' })}
            onChange={handleChange}
            value={values.password}
          />
          {errors.password && !dirty && (
            <ErrorComponent data-testid="error-password">{errors.password}</ErrorComponent>
          )}
        </Field>
        <Field>
          <Input
            data-testid="password-confirm"
            name="password_confirm"
            type="password"
            placeholder={intl.formatMessage({ id: 'resetPassword.repeat-password' })}
            onChange={handleChange}
            value={values.password_confirm}
          />
          {errors.password_confirm && !dirty && (
            <ErrorComponent data-testid="error-password-confirm">
              {errors.password_confirm}
            </ErrorComponent>
          )}
        </Field>
        <Button
          text={
            isSubmitting ? 'Loading...' : intl.formatMessage({ id: 'resetPassword.submit-button' })
          }
          data-testid="submit-button"
          block
          type="submit"
          disabled={!isValid || isSubmitting}
        />
      </StyledForm>
    </>
  );
};

export default ResetPassword;
